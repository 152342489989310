<body class="animated fadeIn fast">
  <app-navbar></app-navbar>

  <div class="container pt-5">
    <h1>About<span class="text-danger">( )</span></h1>
    <hr class="pb-5">
    <div class="animate__animated animate__fadeInLeft">
      <div id="core"class="row">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pb-2">
          <div class="d-block mx-auto" id="img">
            <img id="tablet" class="vertical-align-center pb-3" src="/assets/img/mobile.svg" alt="Responsive">
          </div>
          <h4 class="text-center text-danger"><strong>Responsive</strong></h4>
          <p class="text-center">Responsive webs and apps that will adapt to the size of your device:  small, medium or big.</p>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pb-2">
          <div class="d-block mx-auto" id="img">
            <img class="pb-3" src="/assets/img/exchange.svg" alt="Responsive">
          </div>
          <h4 class="text-center text-danger"><strong>Interaction</strong></h4>
          <p class="text-center">I enjoy creating a dynamic experience, with interaction between the user and the services.</p>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pb-2">
          <div class="d-block mx-auto" id="img">
            <img class="pb-3" src="/assets/img/sitemap.svg" alt="Responsive">
          </div>
          <h4 class="text-center text-danger"><strong>Intuitive</strong></h4>
          <p class="text-center">Easy and intuitive navigation, to make your usability and efficience the most important.</p>
        </div>
      </div>

      <div id="bio" class="pt-4 align-items-center justify-content-center row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-4">
          <img id="foto_bio" class="img-fluid" src="/assets/img/yo4.jpg" alt="Mago">
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-4">
          <h4 class="text-center text-danger">Who am I?</h4>
          <p class="text-center">My name is Jose Mª Farfán, but Mago is my nickname. I finished my studies in 2021: Full Stack JavaScript Bootcamp at BCS, followed by IT formation in Front End & Back End at the <strong class="strong">IT Academy in Cibernarium Barcelona</strong>.</p>
          <p class="text-center">I consider myself a multipotentialite person, with a lot of interests in multiple areas. Among other things I play trumpet and I organize international jazz festivals, two fields that allow me to go deeper into <strong class="strong">creativity, project management and team leading skills</strong>. </p>
          <p class="text-center">In a quest for always keeping myself updated, <strong class="strong">I study, investigate and learn everyday</strong>, feeling a strong commitment with my developement.</p>
        </div>

      </div>
    </div>



  </div>
  <app-footer></app-footer>
</body>


