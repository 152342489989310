
<div id="wrapper">
  <div class="overlay"></div>
  <nav id="barra_nav" class="navbar navbar-expand-lg navbar-dark bg-dark p-2 justify-content-between">
    <button class="hamburger animated fadeInLeft is-closed" type="button" data-toggle="offcanvas" data-target="navbar-expand-lg" >
      <span class="hamb-top"></span>
      <span class="hamb-middle"></span>
      <span class="hamb-bottom"></span>
    </button>
    <a class="navbar-brand" [routerLink]="['/home']"><i class="fas fa-code mr-3"></i></a>
  </nav>

       <!-- Sidebar -->
  <nav class="navbar navbar-inverse fixed-top bg-dark" id="sidebar-wrapper" role="navigation">
    <ul class="nav sidebar-nav">
      <div class="sidebar-header mb-4">
        <div class="sidebar-brand mb-5">
          <div class="mb-2">
            <a class="nav-link" [routerLink]="['/contact']"><img src="/assets/img/yoSwimOut.jpg" class="border border-5 border-danger rounded-circle" ></a>
          </div>
          <h6 id="nombre"><strong>Jose Mª Farfán</strong></h6>
        </div>
      </div>
      <li class="nav-item mb-2">
        <a class="nav-link" [routerLink]="['/home']" routerLinkActive="active">
        <i class="fas fa-home mr-1"></i>Home<span class="text-danger">( )</span></a>
      </li>
      <li class="nav-item mb-2">
        <a class="nav-link" [routerLink]="['/about']" routerLinkActive="active"><i class="fas fa-user mr-2"></i>About<span class="text-danger">( )</span></a>
      </li>
      <li class="nav-item mb-2">
        <a class="nav-link" [routerLink]="['/skills']" routerLinkActive="active"><i class="fas fa-tools mr-2"></i>Skills<span class="text-danger">( )</span></a>
      </li>
      <li class="nav-item mb-2">
        <a class="nav-link" [routerLink]="['/projects']" routerLinkActive="active"><i class="fab fa-chrome mr-2"></i>Projects<span class="text-danger">( )</span></a>
      </li>
      <li class="nav-item mb-2">
        <a class="nav-link" [routerLink]="['/contact']" routerLinkActive="active"><i class="far fa-comments mr-2"></i>Contact<span class="text-danger">( )</span></a>
      </li>

     </ul>
  </nav>
</div>


