<footer class="mt-5 pt-3 pb-2">

  <div class="container d-flex align-items-center justify-content-center">
    <a href="https://www.linkedin.com/in/jose-m%C2%AA-mago-farf%C3%A1n-camacho-7825a244/" target="-blank"><img class="m-3 hover" src="/assets/img/linkedin.svg" alt="linkedin"></a>
    <a href="https://github.com/MagoAlakai" target="-blank"><img class="m-3 hover" src="/assets/img/git.svg" alt="git"></a>
    <a href="https://www.facebook.com/jose.m.farfan.33" target="-blank"><img class="m-3 hover" src="/assets/img/facebook.svg" alt="facebook"></a>
    <a href="https://www.instagram.com/magoalakai/" target="-blank"><img class="m-3 hover" src="/assets/img/instagram.svg" alt="instagram"></a>
  </div>

  <div class="container d-flex align-items-center justify-content-center">
    <img id="copy" class="p-2" src="/assets/img/copyright.svg" alt="">
    <p class="text-secondary pt-3">Jose Mª Farfán <i class="fa fa-refresh" aria-hidden="true"></i><span class="text-danger"> 2021</span></p>
  </div>

</footer>
