<body style="background-image: url('/assets/img/Fondo\ Portafolio.jpg')" class="wrapper animated fadeIn fast">
    <div id="home" class="container align-middle">
      <div class="text">
        Hello, I'm <span class="highlight">Jose Mª Farfán</span>.
        <br>
        <br>
        I'm a full-stack web developer.
      </div>

      <div [routerLink]="['/about']" class="button page-link mt-4">
        View my profile <i class="fas fa-arrow-right ml-1"></i>
      </div>

    </div>
</body>
