<body class="animated fadeIn fast" id="container">
  <app-navbar></app-navbar>

<div class="container pt-5">
  <h1>Skills<span class="text-danger">( )</span></h1>
  <hr class="pb-5">
  <div class="pl-4 row animate__animated animate__fadeInLeft">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-5">
      <h5 class="text-muted">Front End</h5>
      <div class="list-group">
        <div  *ngFor="let skill of skills; let i= index">
          <div class="list-group-item list-group-item-action row justify-content-between align-items-center d-flex" *ngIf="skill.end === 'front'">
            <div class_="col-3">
              <img [src]='skill.img' [alt]="skill.nombre " width="25px" height="25px">
            </div>
            <div class_="col-4">
              <h6 class='align-content-center text-center mb-0'>{{ skill.nombre | lowercase}}</h6>
            </div>
            <div class="col-5">
              <div class="progress">
                <div class="progress-bar bg-info" role="progressbar" [ngStyle]="{'width': skill.barra}" aria-valuenow="skill.barra" aria-valuemin="0" aria-valuemax="100">{{ skill.barra }}</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <h5 class="text-muted">Back End</h5>
      <div class="list-group">
        <div  *ngFor="let skill of skills; let i= index">
          <div class="list-group-item list-group-item-action row justify-content-between align-items-center d-flex" *ngIf="skill.end === 'back'">
            <div class_="col-3">
              <img [src]='skill.img' [alt]="skill.nombre " width="25px" height="25px">
            </div>
            <div class_="col-4">
              <h6 class='align-content-center text-center'>{{ skill.nombre | lowercase}}</h6>
            </div>
            <div class="col-5">
              <div class="progress">
                <div class="progress-bar bg-info" role="progressbar" [ngStyle]="{'width': skill.barra}" aria-valuenow="skill.barra" aria-valuemin="0" aria-valuemax="100">{{ skill.barra }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  </div>
  <app-footer></app-footer>
</body>




