  <body class="animated fadeIn fast" >
    <app-navbar></app-navbar>

    <div class="container pt-5">
      <h1 >Contact<span class="text-danger">( )</span></h1>
      <hr>

      <div id="alert" class="animated fadeIn slow alert alert-success d-none text-center" role="alert">
      Your message has been sent succesfully, I will get in contact with you as soon as possible!
      </div>

      <div class="contact container mt-5 animate__animated animate__fadeInLeft">
      <form id="form" #formContacto="ngForm" (ngSubmit)="onSubmit($event, formContacto)" class="mx-auto row pt-4 pb-4 mb-4">
        <div class="form-group mx-auto col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
            <label for="exampleInputEmail1">Name & last name</label>
            <input
                type="text"
                name="user_name"
                class="form-control"
                placeholder="Introduce your full name"
                [ngClass]="{'is-invalid': nombre.touched && !nombre.valid}"
                #nombre="ngModel" [(ngModel)]="contactoUsuario.nombre"
                required pattern="^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$"
            >
            <div *ngIf="nombre.touched && !nombre.valid" class="invalid-feedback">
                This field is required!
            </div>
        </div>
        <div class="form-group mx-auto col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
            <label id="label" for="exampleInputEmail1">Email</label>
            <input
                type="email"
                name="user_email"
                class="form-control"
                placeholder="Introduce your email"
                [ngClass]="{'is-invalid': email.touched && !email.valid}"
                #email="ngModel"
                [(ngModel)]="contactoUsuario.email"
                required pattern="^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$">
            <div *ngIf="email.touched && !email.valid" class="invalid-feedback">
              This field is required!
            </div>
        </div>
        <div class="form-group mx-auto col-xs-12 col-sm-12 col-md-8 col-lg-12 col-xl-12">
            <label for="exampleFormControlTextarea1">Message</label>
            <textarea
                class="form-control"
                name="message"
                rows="6"
                required
                [ngClass]="{'is-invalid': mensaje.touched && !mensaje.valid}"
                #mensaje="ngModel"
                [(ngModel)]="contactoUsuario.mensaje"
            >
            </textarea>
            <div *ngIf="mensaje.touched && !mensaje.valid" class="invalid-feedback">
                Este campo es obligatorio!
            </div>
        </div>
        <div class="form-group mx-auto col-xs-12 col-sm-12 col-md-8 col-lg-12 col-xl-12">
            <button
                type="submit"
                class="btn btn-outline-info btn-block"
                [disabled]="!formContacto.form.valid"
            >
                Submit
            </button>
        </div>
      </form>

      <div id="datos" class="mx-auto pt-4 pb-4 mb-4">
        <div class="d-flex mt-5 pb-3 datos">
          <img class="ml-5 mr-3" src="../../../assets/img/phone.svg" alt="Phone">
          <div class="d-block">
            <h4 class="text-danger">Contact number</h4>
            <p>+34 697 828 396</p>
          </div>
        </div>

        <div class="d-flex mb-3 datos">
          <img class="ml-5 mr-3"  src="../../../assets/img/mail.svg" alt="Phone">
          <div class="d-block">
            <h4 class="text-danger">Email address</h4>
            <p>magoalakai@gmail.com</p>
          </div>
        </div>

        <div class="d-flex mb-3 datos">
          <img class="ml-5 mr-3" src="../../../assets/img/mapa.svg" alt="Phone">
          <div class="d-block">
            <h4 class="text-danger">My location</h4>
            <p>Barrio de Gracia, 08012 Barcelona</p>
          </div>
        </div>

      </div>
    </div>
      </div>
    <app-footer></app-footer>
  </body>






