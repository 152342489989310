<body class="animated fadeIn fast">
  <app-navbar></app-navbar>

  <div class="container pt-5">
    <h1>Projects<span class="text-danger">( )</span></h1>
    <hr class="mb-5">

    <div class="card-columns animate__animated animate__fadeInLeft" >
      <div class="card" *ngFor="let project of projects; let i= index">
        <img class="card-img-top" [src]='project.img' [alt]="project.titulo">
        <div class="card-body">
          <h5 class="card-title"><strong>{{ project. titulo }}</strong></h5>
          <p class="card-text">{{ project. explicacion }}</p>
          <p class="card-text">{{ project. explicacion2 }}</p>
          <p class="card-text"><small class="text-dark">{{ project.tecnologias }}</small></p>
          <a [href]="project.web" target="_blank"><button type="button" class="btn btn-outline-info btn-block shadow">Visit website</button></a>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</body>
