import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SkillsService {

  private skills: Skill[] = [];

  constructor() {
    this.skills = [
      {
        img:"/assets/img/html.svg",
        nombre: 'HTML5',
        barra: '85%',
        end: 'front'
      },
      {
        img:"/assets/img/css.svg",
        nombre: 'CSS3',
        barra: '75%',
        end: 'front'
      },
      {
        img:"/assets/img/bootstrap.svg",
        nombre: 'Bootstrap',
        barra: '80%',
        end: 'front'
      },
      {
        img:"/assets/img/MudBlazor.png",
        nombre: 'MudBlazor',
        barra: '80%',
        end: 'front'
      },
      {
        img:"/assets/img/jquery.svg",
        nombre: 'Jquery',
        barra: '70%',
        end: 'front'
      },
      {
        img:"/assets/img/sass.svg",
        nombre: 'Sass',
        barra: '70%',
        end: 'front'
      },
      {
        img:"/assets/img/blazor.svg",
        nombre: 'Blazor',
        barra: '80%',
        end: 'front'
      },
      {
        img:"/assets/img/js.svg",
        nombre: 'Javascript',
        barra: '80%',
        end: 'front'
      },
      {
        img:"/assets/img/typescript.svg",
        nombre: 'Typescript',
        barra: '75%',
        end: 'front'
      },
      {
        img:"/assets/img/angular.svg",
        nombre: 'Angular',
        barra: '65%',
        end: 'front'
      },
      {
        img:"/assets/img/c--4.svg",
        nombre: 'C#',
        barra: '80%',
        end: 'back'
      },
      {
        img:"/assets/img/dot-net.svg",
        nombre: '.Net',
        barra: '80%',
        end: 'back'
      },
      {
        img:"/assets/img/postgresql.svg",
        nombre: 'PostgreSQL',
        barra: '75%',
        end: 'back'
      },
      {
        img:"/assets/img/php.svg",
        nombre: 'Php',
        barra: '80%',
        end: 'back'
      },
      {
        img:"/assets/img/laravel.svg",
        nombre: 'Laravel',
        barra: '80%',
        end: 'back'
      },
      {
        img:"/assets/img/docker.svg",
        nombre: 'Docker',
        barra: '65%',
        end: 'back'
      },
      {
        img:"/assets/img/git.svg",
        nombre: 'Git',
        barra: '85%',
        end: 'back'
      },
    ]
  }

  getSkills(): Skill[]{
    return this.skills;
  }
}

export interface Skill{
  img: string;
  nombre: string;
  barra: string;
  end: string;
}
